<template>
  <div class="homePage">
    <!-- 轮播 -->
    <div class="swp-box" @mouseenter="" @mouseleave="">
      <el-carousel
        arrow="hover"
        height="500px"
        indicator-position="none"
        :interval="7000"
        :autoplay="true"
        trigger="click"
      >
        <el-carousel-item v-for="(item, index) in swpUrlData" :key="index">
          <img v-lazy="item" />
        </el-carousel-item>
      </el-carousel>
    </div>

    <!-- 产品中心 -->
    <div class="product-box">
      <div class="top">
        <p>{{ $t('productEn') }}</p>
      </div>
      <div class="swp-pro-center">
        <div class="item" v-for="(item, index) in productSuperurl" :key="index">
          <img v-lazy="item.productImg" />
          <div
            class="img-content"
            @click="smallTitleJumpFun('productDetail', item.id)"
          >
            <div class="title">
              <span class="label">{{ item.productTitle }}</span>
              <span class="btn">
                查看更多<i class="el-icon-d-arrow-right"></i>
              </span>
            </div>
            <p class="text">
              {{ item.productText }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- 解决方案 -->
    <div class="solutions-box">
      <div class="top">
        <p>{{ $t('SolutionsEn') }}</p>
      </div>
      <div class="swp-sol-center">
        <div class="info-panel-box">
          <div class="info-panel">
            <div class="info-title">
              <img src="@/assets/img/gxLogo.png" />
              <h3>{{ solutionsSuperurl.caseTitle }}</h3>
              <!-- <div class="info-more">
                <router-link to="/IndustrySolutions">
                  查看更多<i class="el-icon-d-arrow-right"></i>
                </router-link>
              </div> -->
            </div>
            <div class="info-content">
              <div class="info-text">
                <p>{{ solutionsSuperurl.caseText }}</p>
              </div>
              <div
                v-if="
                  solutionsSuperurl.caseImgExtList ||
                  solutionsSuperurl.caseVideoExtList
                "
                class="info-media"
              >
                <img
                  v-for="(imgPath, index) in solutionsSuperurl.caseImgExtList"
                  :key="index"
                  :src="imgPath"
                />
                <video
                  v-for="(
                    videoPath, index
                  ) in solutionsSuperurl.caseVideoExtList"
                  :key="index"
                  :src="videoPath"
                  controls
                ></video>
              </div>
            </div>
            <div class="info-footer">
              <div class="info-more">
                <router-link to="/IndustrySolutions">
                  查看更多<i class="el-icon-d-arrow-right"></i>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 新闻中心 -->
    <div class="new-case-box">
      <div class="new-case-panel">
        <div class="new-left">
          <div class="new-title">
            <span>{{ $t('newsEn') }}</span>
            <span>{{ $t('News') }} </span>
          </div>
          <div class="new-body">
            <div class="img-box">
              <img :src="newTopUrl" />
            </div>
            <div class="conten-box">
              <div
                class="conten-title"
                @click="smallTitleJumpFun('newsDetail', newListData[0].id)"
              >
                {{ newTopTitle }}
              </div>
              <div class="conten-body"></div>
              <div
                class="content-footer"
                @click="smallTitleJumpFun('newsCenter')"
              >
                {{ $t('moreNews') }}<i class="el-icon-d-arrow-right"></i>
              </div>
            </div>
          </div>
          <div class="new-footer">
            <ul>
              <li
                v-for="(item, index) in newListData"
                :key="index"
                @click="smallTitleJumpFun('newsDetail', item.id)"
              >
                <span>{{ item.newsTitle }}</span>
                <span>{{ item.createDate }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="case-right">
          <div class="case-title">
            <span>{{ $t('ImplementationCase') }}</span>
            <span>{{ $t('case') }}</span>
          </div>
          <div class="case-body">
            <div class="puse-box" :class="[palyVideoFalg ? '' : 'visible']">
              <i class="el-icon-video-play" @click="playVideoFun()"></i>
            </div>
            <div class="video-box">
              <video ref="myVideo" class="video-css" :controls="palyVideoFalg">
                <source :src="homeVideoData" type="video/mp4" />
              </video>
            </div>
          </div>
          <div class="case-footer">
            <span>{{ homeVideoTitle }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'homePage',
  data() {
    return {
      // 新闻中心顶部图片和标题
      newTopUrl: '',
      newTopTitle: '',
      // 新闻中心的数据
      newListData: [],
      // 产品中心的数据
      productSuperurl: [
        // 这些对象是轮播组件判断有多少图片的依据
        {},
        {},
        {},
        {},
        {},
        {}
      ],
      //  解决方案的数据
      solutionsSuperurl: {},
      // 播放视频
      palyVideoFalg: false,
      // 轮播图片数据
      swpUrlData: [],
      // 视频中心数据
      homeVideoData: '',
      homeVideoTitle: ''
    }
  },
  created() {
    this.getSwpData()
  },
  mounted() {
    this.getData()
    this.getProData()
    this.getSolData()
    this.getHomeVideoData()
  },
  methods: {
    // 获取数据信息
    getData() {
      // 新闻列表数据
      let newFormData = { id: '', pageNo: '1', pageSize: '6' }
      this.$axios
        .post('/officialweb/data/findGwNewsInfoList', newFormData)
        .then((res) => {
          this.newListData = res.list.map((item) => {
            item.newsImgs = item.newsImgs
              .replace('[', '')
              .replace(']', '')
              .split(',')[0]
            return item
          })
          this.newListData = this.newListData.slice(0, 6)
          this.newTopUrl = this.newListData[0].newsImgs
          this.newTopTitle = this.newListData[0].newsTitle
        })
        .catch(() => {})
    },
    // 获取首页轮播图片
    getSwpData() {
      this.$axios
        .post('/officialweb/data/getGwIndexHomeBy')
        .then((res) => {
          this.swpUrlData = res.data.homeImgs
            .replace('[', '')
            .replace(']', '')
            .split(',')
        })
        .catch(() => {})
    },
    // 获取首页视频中心的数据
    getHomeVideoData() {
      this.$axios
        .post('/officialweb/data/getGwVideoInfoBy')
        .then((res) => {
          this.homeVideoData = res.data.aboutVideo
            .replace('[', '')
            .replace(']', '')
          this.$refs.myVideo.src = this.homeVideoData
          this.homeVideoTitle = res.data.aboutTitle
        })
        .catch(() => {})
    },
    // 获取产品中心数据
    getProData() {
      // 产品中心列表数据
      let proFormData = { pageNo: '1', pageSize: '6' }
      this.$axios
        .get('/officialweb/data/pageProductInfoV2', proFormData)
        .then((res) => {
          if (res && res.list) {
            this.productSuperurl = this.productSuperurl.map((item, index) => {
              if (res.list[index]) {
                return res.list[index]
              } else {
                return {}
              }
            })
          }
        })
        .catch(() => {})
    },
    // 获取解决方案数据
    getSolData() {
      this.$axios
        .get('/officialweb/data/listCaseInfoV2')
        .then((res) => {
          if (res.code == 0 && res.data) {
            let data = res.data
            // data.map((item) => {
            //   if (item.caseImgExt) {
            //     item.caseImgExt = item.caseImgExt.split(',')
            //   }
            //   if (item.caseVideoExt) {
            //     item.caseVideoExt = item.caseVideoExt.split(',')
            //   }
            // })
            this.solutionsSuperurl = data[0]
          }
        })
        .catch(() => {})
    },
    // 播放视频
    playVideoFun() {
      this.palyVideoFalg = true
      this.$refs.myVideo.play()
    },
    // 跳转到大页面中的小标题页面，也就是进入其他详情页面
    smallTitleJumpFun(routeName, id) {
      // 小的标题进行跳转的操作
      this.$router.push({
        name: routeName,
        params: { id }
      })
    }
  }
}
</script>

<style lang="less" scoped>
// 整体样式分割
.homePage {
  width: 100%;
  overflow: hidden;
  // filter: grayscale(100%);
  // -webkit-filter: grayscale(100%);
  // -moz-filter: grayscale(100%);
  // -ms-filter: grayscale(100%);
}
// 轮播图样式
.swp-box {
  cursor: pointer;
  width: 100%;
  min-width: @mainSize;
  background-color: #7f0e17;
  /deep/ .el-carousel__arrow {
    width: 70px;
    height: 70px;
    background-color: transparent !important;
    i {
      font-size: 60px;
    }
  }
  /deep/ .el-carousel__container {
    button {
      outline: transparent;
    }
  }

  .carousel {
    width: 100%;
    position: relative;
    .carousel-item {
      width: 100%;
    }
    .carousel-inner {
      width: 100%;
    }
  }
  img {
    display: inline-block;
    height: 100%;
    // width: 100%;
    object-fit: cover;
    image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  }
  button {
    width: 50px;
    height: 50px;
    opacity: 1;
    background-color: transparent;
    border: transparent;
    position: absolute;
    top: calc(50% - 25px);
    margin-left: 100px;
    color: #fff;
    font-size: 60px;
    &:last-child {
      margin-left: 0;
      margin-right: 100px;
    }
    .carousel-control-prev-icon,
    .carousel-control-next-icon {
      background-image: none;
    }
  }
}
// 产品中心的样式
.product-box {
  padding: 50px;
  background-color: @lightGrayColor;
  .top {
    width: @mainSize;
    margin: 0 auto;
    height: 120px;
    font-size: 34px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #fff;
    p {
      padding-bottom: 8px;
      background: linear-gradient(@mainColor, @mainColor) no-repeat;
      background-size: 5% 5px;
      background-position: 50% 100%;
      text-shadow: 0.05em 0 #fff, -0.05em 0 #fff;
    }
  }
  .swp-pro-center {
    width: @mainSize;
    margin: 0 auto;
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px 40px;
    background-color: #fff;
    padding: 30px;
    .item {
      width: 420px;
      height: 350px;
      overflow: hidden;
      position: relative;
      background-color: #eee;
      // background-color: @blueColor;
      img {
        width: 420px;
        height: 100%;
        object-fit: cover;
        transition: 0.3s all;
      }
      &:hover {
        img {
          transform: scale(1.1, 1.1);
        }
        box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
        .img-content {
          bottom: 0px;
          .title::before {
            width: 100%;
            transition: 0.3s all;
          }
        }
      }
    }
    .img-content {
      position: absolute;
      left: 0;
      bottom: -60px;
      width: 100%;
      height: 100px;
      text-align: left;
      cursor: pointer;
      overflow: hidden;
      color: #fff;
      z-index: 100;
      box-sizing: border-box;
      background-color: rgba(0, 0, 0, 0.6);
      transition: all 0.3s;
      backdrop-filter: blur(5px);
      .title {
        position: relative;
        display: flex;
        width: 100%;
        height: 40px;
        line-height: 40px;
        margin: 0px auto 10px;
        font-size: 16px;
        font-weight: 700;
        padding: 0 10px;
        box-sizing: border-box;
        background-color: rgba(0, 0, 0, 0.6);
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 0;
          height: 100%;
          box-sizing: border-box;
          border-bottom: 1px solid rgba(255, 255, 255, 0.6);
        }
        &:hover {
          .btn {
            color: @mainColor !important;
          }
        }
        .label {
          flex: 1;
          color: @mainColor;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .btn {
          text-align: right;
          width: 130px;
          height: 100%;
          color: #fff;
          padding-right: 0px;
        }
      }
      .text {
        width: 100%;
        height: 40px;
        line-height: 20px;
        padding: 0 10px;
        font-size: 14px;
        color: @lightGrayColor;
        // text-indent: 2em;
        box-sizing: border-box;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
}
// 解决方案样式
.solutions-box {
  padding-bottom: 50px;
  background-color: @lightGrayColor;
  .top {
    width: @mainSize;
    margin: 0 auto;
    height: 116px;
    font-size: 34px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #fff;
    p {
      padding-bottom: 8px;
      background: linear-gradient(@mainColor, @mainColor) no-repeat;
      background-size: 4% 3.5px;
      background-position: 50% 100%;
      text-shadow: 0.05em 0 #fff, -0.05em 0 #fff;
    }
  }
  .swp-sol-center {
    width: @mainSize;
    margin: 0 auto;
    background-color: #fff;
    padding: 30px;
    box-sizing: border-box;
    .info-panel-box {
      width: 100%;
      padding-top: 30px;
    }
    .info-panel {
      width: 100%;
      .info-title {
        color: @blueColor;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 30px;
        letter-spacing: 2px;
        box-sizing: border-box;
        background-color: @lightGrayColor;
        border-radius: 5px;
        img {
          height: 30px;
          margin-right: 10px;
        }
        h3 {
          margin: 0;
          line-height: 30px;
          font-size: @titleSize;
        }
        .info-more {
          flex: 1;
          overflow: hidden;
          text-align: right;
          a {
            color: #000;
            line-height: 30px;
            padding: 10px 20px;
            &:hover {
              color: @blueColor;
            }
          }
        }
      }
      .info-content {
        width: 100%;
        overflow: hidden;
        .info-text {
          text-align: left;
          padding: 10px;
          margin-top: 10px;
          margin-bottom: 20px;
        }
        .info-media {
          width: 100%;
          margin-bottom: 30px;
          border-radius: 10px;
          overflow: hidden;
          &:last-child {
            margin-bottom: 0px;
          }
          video,
          img {
            width: 100%;
            min-height: 200px;
            margin-bottom: 10px;
            object-fit: cover;
            border-radius: 10px;
            &:last-child {
              margin-bottom: 0px;
            }
          }
        }
      }
      .info-footer {
        .info-more {
          a {
            color: @blueColor;
            line-height: 30px;
            padding: 10px 20px;
            &:hover {
              color: @mainColor;
            }
          }
        }
      }
    }
  }
}
//  新闻中心样式
.new-case-box {
  height: 600px;
  padding-bottom: 50px;
  background-color: @lightGrayColor;
  .new-case-panel {
    display: flex;
    width: @mainSize;
    height: 100%;
    padding: 30px;
    margin: 0 auto;
    margin-bottom: 50px;
    background-color: #fff;
  }
  .new-left {
    width: 50%;
    padding-right: 5px;
    box-sizing: border-box;
    .new-title {
      width: 100%;
      height: 60px;
      font-size: 34px;
      margin-bottom: 20px;
      font-weight: bold;
      display: flex;
      align-items: flex-end;
      span {
        &:last-child {
          margin-left: 10px;
          font-size: 20px;
          color: @darkGrayColor;
        }
        &:first-child::after {
          content: '|';
          margin-left: 10px;
          font-style: normal;
          color: @mainColor;
        }
      }
    }
    .new-body {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      height: 160px;
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 2px dotted @grayColor;
      box-sizing: border-box;
      .img-box {
        width: 300px;
        height: 140px;
        margin-right: 30px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .conten-box {
        width: calc(100% - 330px);
        height: 140px;
        text-align: left;
        overflow: hidden;
        .conten-title {
          width: 100%;
          height: 30px;
          line-height: 30px;
          color: @blueColor;
          font-size: 16px;
          font-weight: bold;
          margin-bottom: 10px;
          cursor: pointer;
          white-space: nowrap;
          &:hover {
            cursor: pointer;
            color: @mainColor;
          }
        }
        .conten-body {
          width: 100%;
          height: 60px;
          overflow: hidden;
          margin-bottom: 10px;
          line-height: 20px;
          font-size: 14px;
          text-indent: 2em;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
          &:hover {
            cursor: pointer;
            color: @blueColor;
          }
        }
        .content-footer {
          width: 120px;
          height: 30px;
          color: #fff;
          text-align: center;
          line-height: 30px;
          font-size: 14px;
          background-color: @blueColor;
          cursor: pointer;
          &:hover {
            color: @mainColor;
          }
        }
      }
    }
    .new-footer {
      width: 100%;
      height: 230px;
      ul {
        width: 100%;
        height: 100%;
        font-size: 16px;
        color: @darkGrayColor;
        text-align: left;
        li {
          width: 100%;
          display: flex;
          line-height: 30px;
          margin-bottom: 10px;
          justify-content: space-between;
          overflow: hidden;
          span {
            &:first-child {
              flex: 1;
              height: 30px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              overflow: hidden;
            }
            &:last-child {
              width: 150px;
              align-self: flex-end;
            }
            &:hover {
              cursor: pointer;
              color: @blueColor;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
  .case-right {
    width: 50%;
    padding-left: 5px;
    box-sizing: border-box;
    overflow: hidden;
    .case-title {
      width: 100%;
      height: 60px;
      font-size: 34px;
      margin-bottom: 20px;
      font-weight: bold;
      display: flex;
      align-items: flex-end;
      span {
        &:last-child {
          margin-left: 10px;
          font-size: 20px;
          color: @darkGrayColor;
        }
        &:first-child::after {
          content: '|';
          margin-left: 10px;
          font-style: normal;
          color: @mainColor;
        }
      }
    }
    .case-body {
      width: 100%;
      height: 360px;
      position: relative;
      overflow: hidden;
      background-color: rgba(0, 0, 0, 0.8);
      .puse-box {
        display: none;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
        color: #fff;
        text-align: center;
        line-height: 360px;
        font-size: 60px;
        font-weight: normal;
        z-index: 10;
        cursor: pointer;
        &.visible {
          display: block;
          z-index: 99;
        }
      }
      .video-box {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 2;
        .video-css {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .case-footer {
      width: 100%;
      text-align: center;
      font-weight: 700;
      letter-spacing: 1px;
      font-size: 16px;
      line-height: 40px;
      color: @lightGrayColor;
      border-top: 1px solid @darkGrayColor;
      background-color: rgba(0, 0, 0);
    }
  }
}
</style>
